.incomeTaxReturnContainer {
  border: 1px solid #121212;
  margin: 10px;

  .borderedBox {
    border: 1px solid black;
  }

  .borderedBoxHeaderRight {
    border-left: 1px solid black;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
  }

  .headerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .formHeading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      font-weight: bold;
    }

    h1 {
      font-weight: bold;
      font-size: 60px;
    }
  }

  .formHeadingRight {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-weight: bolder;
    }
  }

  .rightHeaderRow {
    display: flex;
    text-align: center;

    h1 {
      font-weight: bold;
      font-size: 60px;
      text-align: center;
    }

    p {
      padding: 10px;
    }
  }

  .checkBoxContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px 0px;

    :global(.ant-form-item) {
      margin-top: 10px;
      width: 90%;

      :global(.ant-checkbox-group) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }

    .fillingStatus {
      margin-left: 25px;
    }


  }

  .fieldContainer {
    border: 1px solid black;

    :global(.ant-form-item) {
      width: 100%;
    }

    :global(.ant-row) {
      padding: 20px;
      //   border: 1px solid black;
    }
  }

  .formItemStyle {
    border: 1px solid #121212;
    margin-bottom: 0;
  }

  .noMarginBottom {
    margin-bottom: 0;
  }

  .alignCenter {
    text-align: center;
  }

  .borderRight {
    border-right: 1px solid #121212;
  }

  .borderLeft {
    border-left: 1px solid #121212;
  }

  .borderBottom {
    border-bottom: 1px solid #121212;
  }

  .borderTop {
    border-top: 1px solid #121212;
  }
  .borderedStyle {
    > div {
      border: 1px solid #121212;
    }
  }

  .formulaInput {
    opacity: 75%;
    border-color: green !important;
    color: #121212;
    input {
      border-color: green !important;
      color: #121212;
    }

    :global(.ant-input-group-addon) {
      border-color: green !important;
      color: #121212;

      b {
        color: green !important;
      }
    }
  }

  .addOnBefore {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #d9d9d9;
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noLeftBorderRadius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
